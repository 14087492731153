import '../../static/css/reset.css'
import '../../static/css/wrapper.css'
import '../../static/css/header.css'
import '../../static/css/breadcrumb.css'
import '../../static/css/contact.css'
import '../../static/css/footer.css'
import React from 'react'
import { Header } from '../components/Header'
import { Breadcrumb } from '../components/Breadcrumb'
import { Footer } from '../components/Footer'
import Seo from '../components/Seo'
import { url } from '../config/domain'

export default function Contacts({ location }: any): JSX.Element {
  const locatePath = location.pathname.split('/')

  return (
    <div>
      <div className="wrapper">
        <Seo
          title={'お問い合わせ一覧'}
          description={
            'KURORO BLOGを運営するためのお問い合わせ一覧詳細ページです。KURORO BLOGは、「モノづくりから始まるエンジニア」をコンセプトに、プログラミングに関心を持ってもらうための情報共有サイトです。'
          }
          url={url + 'contacts/'}
          noIndex={true}
          contentType="article"
        />
        <Header location={locatePath} />

        <h1 className="contacts__h1">お問い合わせ一覧(ご意見・ご要望)</h1>
        <div className="contacts__contents">
          <ul className="contacts__comments">
            <li>
              ご入力頂いた個人情報は、個人情報保護法を遵守し、お客様とのコミュニケーションのために利用させていただくもので、それ以外の目的で使用することはございません。
            </li>
            <li>お問い合わせいただきました内容によっては、お答えできない場合やお時間がかかる場合がございます。</li>
            <li>
              また、土日祝日にいただいたお問い合わせにつきましては、翌営業日以降のご返信となりますので、あらかじめご了承ください。
            </li>
          </ul>
          <div className="contacts__links">
            <a className="contacts__links--company" href="/contacts/company/">
              <div className="contacts__linksBox">
                <p>法人の方はこちら</p>
              </div>
            </a>
            <a className="contacts__links--user" href="/contacts/user/">
              <div className="contacts__linksBox">
                <p>個人の方はこちら</p>
              </div>
            </a>
          </div>
        </div>

        <Breadcrumb
          breadcrumb={[
            {
              link: '/',
              text: 'KURORO BLOG',
            },
            {
              link: '/contacts/',
              text: 'お問い合わせ一覧',
            },
          ]}
        />
        <Footer />
      </div>
    </div>
  )
}
